import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './StockEssence.css';
import BasicInfo from './BasicInfo';
import ManagerInfo from './ManagerInfo';
import FinancialData from './FinancialData';
import MarketPerformance from './MarketPerformance';
import moment from 'moment';

function StockEssence() {
    const navigate = useNavigate();
    const [stockData, setStockData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredStocks, setFilteredStocks] = useState([]);
    const [selectedStock, setSelectedStock] = useState(null);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [managersInfo, setManagersInfo] = useState([]);
    const [salariesInfo, setSalariesInfo] = useState([]);
    const [financialInfo, setFinancialInfo] = useState(null);
    const [incomeInfo, setIncomeInfo] = useState(null);
    const [balanceSheetInfo, setBalanceSheetInfo] = useState(null);
    const [cashflowInfo, setCashflowInfo] = useState(null);
    const [financialIndicatorInfo, setFinancialIndicatorInfo] = useState(null);
    const [mainBusinessInfo, setMainBusinessInfo] = useState(null);
    const [marketPerformanceInfo, setMarketPerformanceInfo] = useState(null);
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    const [reportDates] = useState(['0331', '0630', '0930', '1231']);
    const [currentTradeDate, setCurrentTradeDate] = useState(moment().format('YYYYMMDD'));

    const getFieldLabel = (key) => {
        const fieldLabels = {
            name: '公司名称',
            enname: '英文名称',
            fullname: '公司全称',
            ts_code: '股票代码',
            market: '市场',
            list_status: '上市状态',
            list_date: '上市日期',
            delist_date: '退市日期',
            trade_unit: '交易单位',
            isin: '国际证券识别码',
            curr_type: '货币类型',
            industry: '所属行业',
            chairman: '董事长',
            manager: '总经理',
            secretary: '董秘',
            reg_capital: '注册资本',
            setup_date: '成立日期',
            province: '所在省份',
            city: '所在城市',
            introduction: '公司介绍',
            website: '公司网站',
            email: '电子邮箱',
            office: '办公地址',
            employees: '员工人数',
            main_business: '主要业务及产品',
            business_scope: '经营范围'
        };
        return fieldLabels[key] || key;
    };

    useEffect(() => {
        const fetchStockList = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/api/stock-essence');
                setStockData(response.data);
            } catch (error) {
                console.error('Error fetching stock list:', error);
                setError('获取股票列表失败');
            }
            setLoading(false);
        };

        fetchStockList();
    }, []);

    useEffect(() => {
        if (searchTerm.length > 0 && stockData) {
            const filtered = stockData.filter(stock =>
                (stock.name && stock.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (stock.enname && stock.enname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (stock.ts_code && stock.ts_code.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setFilteredStocks(filtered.slice(0, 100));
        } else {
            setFilteredStocks([]);
        }
    }, [searchTerm, stockData]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setIsDropdownVisible(true);
    };

    const handleStockSelect = async (stock) => {
        setCurrentTradeDate(moment().format('YYYYMMDD'));
        setIsDropdownVisible(false);
        setSelectedStock(stock);
        setCompanyInfo(null);
        setManagersInfo([]);
        setSalariesInfo([]);
        setFinancialInfo(null);
        setIncomeInfo(null);
        setBalanceSheetInfo(null);
        setCashflowInfo(null);
        setFinancialIndicatorInfo(null);
        setMainBusinessInfo(null);
        setMarketPerformanceInfo(null);

        try {
            setLoading(true);
            const response = await axios.get(`/api/stock-essence/company`, {
                params: { ts_code: stock.ts_code }
            });
            console.log('接收到的公司数据:', response.data);
            if (response.data) {
                setCompanyInfo(response.data.company || null);
                setManagersInfo(response.data.managers || []);
                setSalariesInfo(response.data.salaries || []);
                setFinancialInfo(response.data.financial || null);
                setIncomeInfo(response.data.income || null);
                setBalanceSheetInfo(response.data.balanceSheet || null);
                setCashflowInfo(response.data.cashflow || null);
                setFinancialIndicatorInfo(response.data.financialIndicator || null);
                setMainBusinessInfo(response.data.mainBusiness || null);
                if (response.data.marketPerformance && response.data.marketPerformance.items) {
                    console.log('接收到的市场表现数据:', response.data.marketPerformance);
                    if (response.data.marketPerformance.items.length > 0) {
                        setMarketPerformanceInfo(response.data.marketPerformance);
                    } else {
                        console.warn('市场表现数据项为空');
                        setMarketPerformanceInfo(null);
                    }
                } else {
                    console.warn('未收到市场表现数据');
                    setMarketPerformanceInfo(null);
                }
                setCurrentTradeDate(response.data.currentTradeDate || moment().format('YYYYMMDD'));
            }
        } catch (error) {
            console.error('Error fetching company info:', error);
            setError('获取公司信息失败');
        } finally {
            setLoading(false);
        }
    };

    const processManagersInfo = (managers, salaries) => {
        const processedManagers = {};
        managers.forEach(manager => {
            const key = manager[2]; // 使用姓名作为键
            if (!processedManagers[key]) {
                processedManagers[key] = {
                    name: manager[2],
                    gender: manager[3],
                    edu: manager[6],
                    beginDate: manager[9],
                    resume: manager[11],
                    titles: [manager[5]],
                    salaries: {}
                };
            } else {
                if (!processedManagers[key].titles.includes(manager[5])) {
                    processedManagers[key].titles.push(manager[5]);
                }
            }
        });

        salaries.forEach(salary => {
            const key = salary[3]; // 使用姓名作为键
            if (processedManagers[key]) {
                const salaryKey = `${salary[2]}_${salary[4]}`; // 使用 end_date 和 title 作为唯一键
                processedManagers[key].salaries[salaryKey] = {
                    annDate: salary[1],
                    endDate: salary[2],
                    title: salary[4],
                    reward: salary[5],
                    holdVol: salary[6],
                    reportName: salary[7]
                };
            }
        });

        // 将 salaries 对象转换回数组
        Object.values(processedManagers).forEach(manager => {
            manager.salaries = Object.values(manager.salaries);
        });

        return Object.values(processedManagers);
    };

    const handleDateChange = async (event) => {
        const selectedYear = moment().format('YYYY');
        const newDate = `${selectedYear}${event.target.value}`;
        setSelectedDate(newDate);
        try {
            const response = await axios.post('/api/stock-essence/update-trade-date', { newDate });
            setCurrentTradeDate(response.data.newDate);
            if (selectedStock) {
                handleStockSelect(selectedStock);
            }
        } catch (error) {
            console.error('更新交易日期失败:', error);
            setError('更新交易日期失败，请稍后再试。');
        }
    };

    return (
        <div className="stock-essence">
            <div className="se-wrapper">
                <div className="se-header">
                    <h1 className="se-title">股票的本质</h1>
                    <button className="se-back-button" onClick={() => navigate('/all-software')}>返回</button>
                </div>
                <div className="se-content">
                    <div className="se-search-section">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="输入公司名称、英文称或股票代码"
                            className="se-search-input"
                        />
                        {isDropdownVisible && filteredStocks.length > 0 && (
                            <ul className="se-search-results">
                                {filteredStocks.map((stock, index) => (
                                    <li key={index} onClick={() => handleStockSelect(stock)}>
                                        {stock.name || stock.enname || '未知'} ({stock.ts_code}) - {stock.market || '未知市场'}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    {/* <div className="se-date-picker">
                        <label htmlFor="trade-date">选择财报日期: </label>
                        <select
                            id="trade-date"
                            value={selectedDate.slice(4)}
                            onChange={handleDateChange}
                        >
                            {reportDates.map((date) => (
                                <option key={date} value={date}>
                                    {date.slice(0, 2) + '月' + date.slice(2) + ''}
                                </option>
                            ))}
                        </select>
                    </div> */}
                    <div className="se-results-section">
                        {/* 基本信息卡片 */}
                        {selectedStock && companyInfo && <BasicInfo selectedStock={selectedStock} companyInfo={companyInfo} getFieldLabel={getFieldLabel} selectedDate={currentTradeDate} />}
                        
                        {/* 管理层信息卡片 */}
                        {managersInfo.length > 0 && <ManagerInfo managersInfo={managersInfo} salariesInfo={salariesInfo} processManagersInfo={processManagersInfo} selectedDate={currentTradeDate} />}
                        
                        {/* 财务数据卡片 */}
                        {companyInfo && <FinancialData financialInfo={financialInfo} incomeInfo={incomeInfo} balanceSheetInfo={balanceSheetInfo} cashflowInfo={cashflowInfo} financialIndicatorInfo={financialIndicatorInfo} mainBusinessInfo={mainBusinessInfo} selectedDate={currentTradeDate} />}

                        {/* 市场表现卡片 */}
                        {marketPerformanceInfo && (
                            <div className="se-horizontal-scroll">
                                <div className="se-scroll-content">
                                    <MarketPerformance marketPerformanceInfo={marketPerformanceInfo} selectedDate={currentTradeDate} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {error && <div className="se-error">{error}</div>}
                {loading && <div className="se-loading">加载中...</div>}
            </div>
            
        </div>
    );
}

export default StockEssence;
