import React from 'react';
import { Link } from 'react-router-dom';

function Banner() {
  return (
    <div className="banner">
      <p>欢迎来到我的小站，正在努力建设中，感谢期待。<Link to="/all-software">选购</Link></p>
    </div>
  );
}

export default Banner;
