import React, { useState } from 'react';
import './CustomerSupport.css';
import weChatQR from '../../assets/images/weChatQR.JPG';
import weChatPay from '../../assets/images/weChatPay.JPG';
import aliPay from '../../assets/images/aliPay.JPG';

function CustomerSupport() {
  const [showQR, setShowQR] = useState(false);
  const [showPayQR, setShowPayQR] = useState(false);

  const handleContactClick = (e) => {
    e.preventDefault();
    setShowQR(true);
  };

  const handleCloseQR = () => {
    setShowQR(false);
    setShowPayQR(false);
  };

  const handlePayClick = (e) => {
    e.preventDefault();
    setShowPayQR(true);
  };

  return (
    <div className="customer-support">
      <div className="support-item">
        <div className="support-icon">📧</div>
        <div className="support-text">
          <p>有问题或建议？</p>
          <a href="#" onClick={handleContactClick}>联系我</a>
        </div>
      </div>
      <div className="support-item">
        <div className="support-icon">🌟</div>
        <div className="support-text">
          <p>喜欢我的作品？</p>
          <a href="#" onClick={handlePayClick}>打赏支持</a>
        </div>
      </div>
      {showQR && (
        <div className="qr-modal" onClick={handleCloseQR}>
          <div className="qr-content" onClick={(e) => e.stopPropagation()}>
            <img src={weChatQR} alt="WeChat QR Code" />
          </div>
        </div>
      )}
      {showPayQR && (
        <div className="qr-modal" onClick={handleCloseQR}>
          <div className="qr-content pay-qr-content" onClick={(e) => e.stopPropagation()}>
            <img src={weChatPay} alt="WeChat Pay QR Code" />
            <img src={aliPay} alt="Alipay QR Code" />
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomerSupport;
