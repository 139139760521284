import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './BlogPage.css';
import Search from '../../components/Header/Search';
import { API_URL } from '../../config';
import { useLocation } from 'react-router-dom';


function BlogPage() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const headerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    setPosts([]);
    setCurrentPage(1);
    fetchPosts();
  }, [location]);

  const fetchPosts = async () => {
    try {
      console.log('Fetching posts from:', `${API_URL}/api/posts/page/${currentPage}`);
      const response = await fetch(`${API_URL}/api/posts/page/${currentPage}`);
      console.log('Response status:', response.status);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched data:', data);
      setPosts(prevPosts => [...prevPosts, ...data.posts]);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("获取文章列表时出错:", error);
      // 在这里添加用户友好的错误处理，例如显示错误消息
    }
  };

  const handleSearchToggle = (isOpen) => {
    setIsSearchOpen(isOpen);
    document.body.classList.toggle('search-open', isOpen);
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  return (
    <div className="blog-page">
      <header className="blog-header" ref={headerRef}>
        {/* 保持原有的头部内容 */}
      </header>
      <div className={`blog-content ${isSearchOpen ? 'blur' : ''}`}>
        <h1>最新动态</h1>
        <div className="blog-grid">
          {posts.length > 0 && (
            <Link to={`/blog/${posts[0]._id}`} className="blog-post main-post">
              <img src={posts[0].coverImage ? `${API_URL}/${posts[0].coverImage}` : `https://via.placeholder.com/980x551`} alt={posts[0].title} />
              <div className="blog-post-content">
                <div className="blog-post-tag">{posts[0].tag}</div>
                <h2>{posts[0].title}</h2>
                <p>{new Date(posts[0].createdAt).toLocaleDateString()}</p>
              </div>
            </Link>
          )}
          
          {posts.length > 1 && (
            <div className="secondary-posts">
              {posts.slice(1, 3).map((post) => (
                <Link to={`/blog/${post._id}`} key={post._id} className="blog-post secondary-post">
                  <img src={post.coverImage ? `${API_URL}/${post.coverImage}` : `https://via.placeholder.com/480x270`} alt={post.title} />
                  <div className="blog-post-content">
                    <div className="blog-post-tag">{post.tag}</div>
                    <h2>{post.title}</h2>
                    <p>{new Date(post.createdAt).toLocaleDateString()}</p>
                  </div>
                </Link>
              ))}
            </div>
          )}
          
          {posts.length > 3 && (
            <div className="grid-posts">
              {posts.slice(3).map((post) => (
                <Link to={`/blog/${post._id}`} key={post._id} className="blog-post grid-post">
                  <img src={post.coverImage ? `${API_URL}/${post.coverImage}` : `https://via.placeholder.com/320x320`} alt={post.title} />
                  <div className="blog-post-content">
                    <div className="blog-post-tag">{post.tag}</div>
                    <h2>{post.title}</h2>
                    <p>{new Date(post.createdAt).toLocaleDateString()}</p>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
        {currentPage < totalPages && (
          <button onClick={handleLoadMore} className="load-more-button">加载更多</button>
        )}
      </div>
    </div>
  );
}

export default BlogPage;
