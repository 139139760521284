import React, { useState } from 'react';
import Home from './Home';
import App1 from './Links/App1';
import App2 from './Links/App2';
import App3 from './Links/App3';
import App4 from './Links/App4.js';
import App5 from './Links/App5';
import App6 from './Links/App6';
import App7 from './Links/App7';
import App8 from './Links/App8';
import App9 from './Links/App9';
import Support from './Links/Support';
import Projects from './Links/Projects';
import Blog from './Links/Blog';
import About from './Links/About';
import Contact from './Links/Contact';
import Services from './Links/Services';
import Resources from './Links/Resources';
import Community from './Links/Community';
import Search from './Links/Search';

const Navigation = ({ className }) => {
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const navItems = ['所有软件', '博客', '关于', '联系', '服务', '资源', '社区', '支持'];

  const linkComponents = {
    '所有软件': Projects,
    '博客': Blog,
    '关于': About,
    '联系': Contact,
    '服务': Services,
    '资源': Resources,
    '社区': Community,
    '支持': Support
  };

  return (
    <nav className={`main-nav ${className}`}>
      <ul>
        {navItems.map((item, index) => {
          const LinkComponent = linkComponents[item];
          return (
            <li key={index}>
              {item === '搜索' ? (
                <Search />
              ) : (
                <LinkComponent />
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navigation;