import React, { useState } from 'react';
import moment from 'moment';

function MarketPerformance({ marketPerformanceInfo, selectedDate }) {
    const [expandedMarketPerformance, setExpandedMarketPerformance] = useState(false);

    const formatValue = (value, decimals = 2) => {
        return value !== undefined && value !== null ? Number(value).toFixed(decimals) : '未知';
    };

    if (!marketPerformanceInfo || !marketPerformanceInfo.items) {
        console.warn('市场表现数据缺失:', marketPerformanceInfo);
        return <div>暂无市场表现数据或数据加载中...</div>;
    }

    console.log('MarketPerformance 接收到的数据:', marketPerformanceInfo);
    const fields = marketPerformanceInfo.fields;
    const items = marketPerformanceInfo.items;
    console.log('处理前的字段:', fields);
    console.log('处理前的项目:', items);

    const fieldMapping = {
        'trade_date': { label: '交易日期', description: '股票交易的日期' },
        'open': { label: '开盘价', description: '当日开盘价', unit: '港元' },
        'high': { label: '最高价', description: '当日最高价', unit: '港元' },
        'low': { label: '最低价', description: '当日最低价', unit: '港元' },
        'close': { label: '收盘价', description: '当日收盘价', unit: '港元' },
        'pre_close': { label: '昨收价', description: '前一交易日收盘价', unit: '港元' },
        'change': { label: '涨跌额', description: '当日涨跌额', unit: '港元' },
        'pct_chg': { label: '涨跌幅', description: '当日涨跌幅', unit: '%' },
        'vol': { label: '成交量', description: '成交量', unit: '手' },
        // 'amount': { label: '成交额', description: '成交额', unit: '千港元' },
        'pe_ttm': { label: '市盈率(TTM)', description: '反映股票的投资价值，数值越低可能越具有投资价值', unit: '' },
        'pb': { label: '市净率', description: '反映股票的账面价值，数值越低可能越被低估', unit: '' },
        'total_mv': { label: '总市值', description: '公司在股票市场上的总价值', unit: '亿港元' },
        'circ_mv': { label: '流通市值', description: '可在市场上自由交易的股票价值', unit: '亿港元' },
        'turnover_rate': { label: '换手率', description: '反映股票的交易活跃程度', unit: '%' },
        'amount': { label: '成交额', description: '成交额', unit: '千港元' }
    };

    const processedData = items.map(item => {
        return fields.reduce((acc, field, index) => {
            let value = item[index];
            let unit = '';
            if (field === 'trade_date') {
                value = moment(value, 'YYYYMMDD').format('YYYY-MM-DD');
            } else if (['open', 'high', 'low', 'close', 'pre_close', 'change'].includes(field)) {
                value = formatValue(value, 2);
                unit = '港元';
            } else if (field === 'vol') {
                value = formatValue(value / 100, 2);
                unit = '手';
            } else if (field === 'amount') {
                value = formatValue(value / 1000, 2);
                unit = '千港元';
            } else if (field === 'pct_chg') {
                value = formatValue(value, 2);
                unit = '%';
            }
            acc[field] = { value, unit };
            return acc;
        }, {});
    });

    console.log('处理后的数据:', processedData);

    // 按日期排序，确保最新的数据在前
    processedData.sort((a, b) => moment(b.trade_date.value, 'YYYY-MM-DD').diff(moment(a.trade_date.value, 'YYYY-MM-DD')));

    console.log('整理后的处理数据:', processedData);

    const recentData = processedData;

    console.log('MarketPerformance 组件接收到的原始数据:', marketPerformanceInfo);

    return (
        <div className="se-section">
            <h3>市场表现</h3>
            <div className="se-market-performance">
                <h4 onClick={() => setExpandedMarketPerformance(!expandedMarketPerformance)} className="se-market-performance-header">
                    近30天日线情况
                    <span className={`se-expand-icon ${expandedMarketPerformance ? 'expanded' : ''}`}>▼</span>
                </h4>
                
                {expandedMarketPerformance && (
                    <div className="se-market-performance-content">
                        <table className="se-market-performance-table">
                            <thead>
                                <tr>
                                    <th className="fixed-column">交易日期</th>
                                    {fields.filter(field => field !== 'trade_date' && field !== 'ts_code').map((field, index) => (
                                        <th key={index} title={fieldMapping[field]?.description || ''} className="right-align">
                                            {fieldMapping[field]?.label || field}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {recentData.map((item, index) => (
                                    <tr key={index}>
                                        <td className="fixed-column">{item['trade_date'].value}</td>
                                        {fields.filter(field => field !== 'trade_date' && field !== 'ts_code').map((field, fieldIndex) => (
                                            <td key={fieldIndex} className="right-align">
                                                {item[field].value}{item[field].unit}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MarketPerformance;
