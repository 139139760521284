import React, { useState } from 'react';
import weChatQR from '../../assets/images/weChatQR.JPG';

function FooterBottom() {
  const [showQR, setShowQR] = useState(false);
  const emailAddresses = '1054023430a@gmail.com,1054023430@qq.com';
  const subject = '来自您的个人网站的邮件';
  const body = '您好，我是从您的个人网站发送这封邮件的。';

  const mailtoLink = `mailto:${emailAddresses}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  const handleSocialMediaClick = (e) => {
    e.preventDefault();
    setShowQR(true);
  };

  const handleCloseQR = () => {
    setShowQR(false);
  };

  return (
    <div className="footer-bottom">
      <p>更多联系方式：<a href={mailtoLink}>发送邮件</a>或<a href="#" onClick={handleSocialMediaClick}>社交媒体</a>。</p>
      {showQR && (
        <div className="qr-modal" onClick={handleCloseQR}>
          <div className="qr-content" onClick={(e) => e.stopPropagation()}>
            <img src={weChatQR} alt="WeChat QR Code" />
          </div>
        </div>
      )}
    </div>
  );
}

export default FooterBottom;
