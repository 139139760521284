import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { FaClipboardList, FaHeart, FaUserCircle, FaSignInAlt } from 'react-icons/fa';

const ShoppingBagDropdown = forwardRef(({ isOpen }, ref) => {
  if (!isOpen) return null;

  return (
    <div className="shopping-bag-dropdown-overlay" ref={ref}>
      <div className="shopping-bag-dropdown-content">
        <h3>你的购物袋是空的。</h3>
        <p className="view-recent">
          <Link to="/login" className="login-link">登录</Link>
          <span className="view-recent-text">查看你最近看过的商品</span>
        </p>
        <p className="account-title">个人资料</p>
        <ul className="account-links">
          <li><FaClipboardList /><Link to="/orders">订单</Link></li>
          <li><FaHeart /><Link to="/favorites">你的收藏</Link></li>
          <li><FaUserCircle /><Link to="/account">账户</Link></li>
          <li><FaSignInAlt /><Link to="/login">登录</Link></li>
        </ul>
      </div>
    </div>
  );
});

export default ShoppingBagDropdown;