import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { API_URL } from '../../config';
import './MarkdownViewer.css';

function MarkdownViewer() {
  const [content, setContent] = useState('');
  const [error, setError] = useState(null);
  const { filename } = useParams();

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/docs/${filename}`);
        setContent(response.data.content);
      } catch (err) {
        setError('文档加载失败');
        console.error('Error fetching markdown:', err);
      }
    };

    fetchMarkdown();
  }, [filename]);

  if (error) return <div className="error">{error}</div>;

  return (
    <div className="markdown-viewer">
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
}

export default MarkdownViewer;
