import React, { useState, useRef, useEffect } from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import ShoppingBag from './ShoppingBag';
import ShoppingBagDropdown from './ShoppingBagDropdown';
import MobileMenu from './MobileMenu';
import { FaSearch } from 'react-icons/fa';

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isShoppingBagOpen, setIsShoppingBagOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleShoppingBag = () => {
    setIsShoppingBagOpen(!isShoppingBagOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsShoppingBagOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="main-header">
      <div className="header-container">
        <div className="header-left">
          <Logo />
        </div>
        <div className="header-center">
          <Navigation />
        </div>
        <div className="header-right">
          <div className="header-icons">
            <FaSearch className="header-icon search-icon" />
            <ShoppingBag onClick={toggleShoppingBag} />
          </div>
          <div className="menu-icon" onClick={toggleMobileMenu}>
            <div className="menu-icon-bar"></div>
            <div className="menu-icon-bar"></div>
            <div className="menu-icon-bar"></div>
          </div>
        </div>
      </div>
      <ShoppingBagDropdown isOpen={isShoppingBagOpen} ref={dropdownRef} />
      <MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
    </header>
  );
}

export default Header;