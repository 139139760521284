import React from 'react';
import { FaShoppingBag } from 'react-icons/fa';

function ShoppingBag({ onClick }) {
  return (
    <div className="shopping-bag" onClick={onClick}>
      <FaShoppingBag size={20} />
    </div>
  );
}

export default ShoppingBag;
