import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const DocSidebar = () => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const response = await axios.get('/api/docs');
        setDocs(response.data);
      } catch (err) {
        console.error('Error fetching doc list:', err);
      }
    };

    fetchDocs();
  }, []);

  return (
    <div className="doc-sidebar">
      <h3>文档列表</h3>
      <ul>
        {docs.map((doc, index) => (
          <li key={index}>
            <Link to={`/docs/${doc}`}>{doc}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DocSidebar;