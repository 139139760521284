import React, { useState } from 'react';
import './Search.css';

function Search({ onToggle, headerHeight }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSearch = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    document.body.style.overflow = newIsOpen ? 'hidden' : 'auto';
    onToggle(newIsOpen);
  };

  const quickLinks = [
    ['个人网站', '博客平台', '在线工具', '移动应用'],
    ['AI助手', '数据分析', '学习资源', '开源项目'],
    ['社区论坛', '最新动态', '使用指南', '技术支持']
  ];

  console.log('Received header height:', headerHeight);

  return (
    <div className="search-container">
      <div className="search-icon" onClick={toggleSearch}>
        {isOpen ? (
          <span className="close-icon">✕</span>
        ) : (
          <span className="search-text">🔍</span>
        )}
      </div>
      {isOpen && (
        <div className="search-overlay">
          <div className="search-content">
            <div className="search-dropdown">
              <div className="close-button" onClick={toggleSearch}>
              </div>
              <input type="text" placeholder="搜索博客文章、教程和软件" className="search-input" />
              <div className="quick-links">
                <h3>快捷链接</h3>
                <div className="quick-links-grid">
                  {quickLinks.flat().filter(Boolean).map((link, index) => (
                    <a key={index} href="#">{link}</a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Search;
