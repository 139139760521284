import React from 'react';
import SoftwareGrid from '../components/SoftwareGrid/SoftwareGrid';
import CustomerSupport from '../components/CustomerSupport/CustomerSupport';

function AllSoftware() {
  return (
    <div className="all-software-page">
      <div className="headline-container">
        <div className="headline">
          <h1 className="headline-text">
            这里是我的<span className="headline-text-gray">软件作品集,</span><br />
            <span className="headline-text-gray">希望能为您带来价值。</span>
          </h1>
        </div>
        <CustomerSupport />
      </div>
      <SoftwareGrid />
    </div>
  );
}

export default AllSoftware;
