import React, { useState } from 'react';

function ManagerInfo({ managersInfo, salariesInfo, processManagersInfo, selectedDate }) {
    const [expandedManagers, setExpandedManagers] = useState({});

    return (
        <div className="se-section">
            <h3>管理层信息</h3>
            {/* <div className="se-date-info">财报日期: {selectedDate}</div> */}
            {processManagersInfo(managersInfo, salariesInfo).map((manager, index) => (
                <div key={index} className="se-manager-item">
                    <h4
                        onClick={() => setExpandedManagers(prev => ({ ...prev, [index]: !prev[index] }))}
                        className="se-manager-header"
                    >
                        {manager.name} - {manager.titles.join('、')}
                        <span className={`se-expand-icon ${expandedManagers[index] ? 'expanded' : ''}`}>▼</span>
                    </h4>
                    {expandedManagers[index] && (
                        <div className="se-manager-info">
                            <div className="se-manager-basic">
                                <p>性别: {manager.gender}</p>
                                {manager.edu && <p>学历: {manager.edu}</p>}
                                <p>任职日期: {manager.beginDate}</p>
                                <p>简历: {manager.resume}</p>
                            </div>
                            <div className="se-manager-salary">
                                {manager.salaries.length > 0 && (
                                    <>
                                        <h5>薪酬信息：</h5>
                                        {manager.salaries.map((salary, idx) => (
                                            <div key={idx} className="se-salary-item">
                                                <p>报告期: {salary.endDate}</p>
                                                <p>职位: {salary.title}</p>
                                                <p>年度薪酬: {salary.reward} 元</p>
                                                <p>持股数量: {salary.holdVol} 股</p>
                                                <p>报告名称: {salary.reportName}</p>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default ManagerInfo;
