import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SoftwareGrid.css';

const products = [
  { name: '盈利能力分析', icon: '📊' },
  { name: '最前沿', icon: '🔬' },
  { name: '股票的本质', icon: '📈' },
];

function SoftwareGrid() {
  const navigate = useNavigate();

  const handleItemClick = (name) => {
    if (name === '盈利能力分析') {
      navigate('/profitability-analysis');
    } else if (name === '最前沿') {
      navigate('/cutting-edge');
    } else if (name === '股票的本质') {
      navigate('/stock-essence');
    }
  };

  return (
    <div className="software-grid-container">
      <div className="software-grid">
        {products.map((product, index) => (
          <div key={index} className="software-item" onClick={() => handleItemClick(product.name)}>
            <div className="software-icon">{product.icon}</div>
            <h3>{product.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SoftwareGrid;
