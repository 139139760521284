import React from 'react';
import logo from '../../assets/logos/zhoukunlinLogoWhiteCharacter.svg';

function Logo() {
  return (
    <a href={window.location.origin} className="logo" style={{
      width: 'auto', 
      height: '44px', 
      display: 'flex', 
      alignItems: 'center',
      justifyContent: 'flex-start'
    }}>
      <img 
        src={logo} 
        alt="周坤林 Logo" 
        style={{ 
          maxWidth: '75%', 
          maxHeight: '80%', 
          objectFit: 'contain'
        }}
        onError={(e) => {
          console.error('Logo加载失败', e);
          e.target.style.display = 'none';
        }}
      />
    </a>
  );
}

export default Logo;
