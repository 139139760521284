import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';

function Login() {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });
      
      if (response.ok) {
        const data = await response.json();
        const { token } = data;
        localStorage.setItem('adminToken', token);
        navigate('/blog-admin');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || '登录失败');
      }
    } catch (error) {
      console.error('登录错误:', error);
      alert(error.message || '登录出错');
    }
  };

  return (
    <div className="login">
      <h2>管理员登录</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="输入管理员密码"
          required
        />
        <button type="submit">登录</button>
      </form>
    </div>
  );
}

export default Login;