import React from 'react';

function FooterLegal() {
  return (
    <div className="footer-legal">
      <div className="footer-legal-left">
        <p>Copyright © 2024 周坤琳. 保留所有权利。</p>
      </div>
      <div className="footer-legal-right">
        <a href="#">隐私政策</a>
        <a href="#">使用条款</a>
        <a href="#">版权声明</a>
        <a href="#">免责声明</a>
        <a href="#">网站地图</a>
      </div>
    </div>
  );
}

export default FooterLegal;
