import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ProfitabilityAnalysis.css';
import ResultItem from './ResultItem';

const API_KEY = '9XhPjXC6gAr4YPJabIHBG7eSoaYqi4Jn'; // 请替换为你的 FMP API 密钥

function ProfitabilityAnalysis() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [financialData, setFinancialData] = useState({});
  const [results, setResults] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    console.log('Search term changed:', searchTerm);
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length > 2) {
        console.log('Triggering search for:', searchTerm);
        searchCompanies(searchTerm);
      } else {
        console.log('Clearing search results');
        setSearchResults([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const searchCompanies = async (query) => {
    console.log('Searching for:', query);
    setIsLoading(true);
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/search?query=${query}&limit=10&apikey=${API_KEY}`);
      console.log('API Response:', response.data);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching companies:', error.response ? error.response.data : error.message);
      setSearchResults([]);
      if (error.response && error.response.status === 429) {
        setError('已达到今日最大查询次数（250次/天），请明天再试或升级到付费计划。');
      } else {
        setError('搜索公司时出现错误，请稍后再试。');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const selectCompany = async (company) => {
    setSelectedCompany(company);
    setIsLoading(true);
    setResults({});
    await fetchFinancialData(company.symbol);
  };

  const fetchFinancialData = async (symbol) => {
    setError(null);
    try {
      const [incomeStatement, balanceSheet] = await Promise.all([
        axios.get(`https://financialmodelingprep.com/api/v3/income-statement/${symbol}?period=annual&limit=1&apikey=${API_KEY}`),
        axios.get(`https://financialmodelingprep.com/api/v3/balance-sheet-statement/${symbol}?period=annual&limit=1&apikey=${API_KEY}`)
      ]);
      
      const incomeData = incomeStatement.data[0];
      const balanceData = balanceSheet.data[0];
      
      if (!incomeData || !balanceData) {
        throw new Error('无法获取完整的财务数据');
      }
      
      setFinancialData({
        netProfit: incomeData.netIncome,
        revenue: incomeData.revenue,
        grossProfit: incomeData.grossProfit,
        operatingProfit: incomeData.operatingIncome,
        totalAssets: balanceData.totalAssets,
        shareholdersEquity: balanceData.totalStockholdersEquity,
        outstandingShares: balanceData.commonStockSharesOutstanding,
      });
      calculateRatios();
    } catch (error) {
      console.error('Error fetching financial data:', error);
      setError('无法获取财务数，请稍后再试。');
      setFinancialData({});
      setResults({});
    } finally {
      setIsLoading(false);
      setShowResults(true);
      setTimeout(() => {
        setIsLoading(false);
        setShowResults(true);
      }, 1000); // 1秒延迟
    }
  };

  const calculateRatios = () => {
    const {
      netProfit,
      revenue,
      grossProfit,
      operatingProfit,
      totalAssets,
      shareholdersEquity,
      outstandingShares,
    } = financialData;

    const calculateRatio = (numerator, denominator) => 
      numerator && denominator ? (numerator / denominator) * 100 : null;

    setResults({
      netProfitMargin: calculateRatio(netProfit, revenue),
      grossProfitMargin: calculateRatio(grossProfit, revenue),
      operatingProfitMargin: calculateRatio(operatingProfit, revenue),
      eps: netProfit && outstandingShares ? netProfit / outstandingShares : null,
      roa: calculateRatio(netProfit, totalAssets),
      roe: calculateRatio(netProfit, shareholdersEquity),
    });
  };

  return (
    <div className="profitability-analysis">
      <div className="pa-container">
        <h1 className="pa-title">盈利能力分析</h1>
        <button className="pa-back-button" onClick={() => navigate('/all-software')}>返回</button>
        <div className="pa-content">
          <div className="pa-search-section">
            <div className="pa-search">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="输入公司名称"
                className="pa-search-input"
              />
              <div className="pa-search-results-container">
                {isLoading ? (
                  <div className="pa-loading">正在搜索...</div>
                ) : searchResults.length > 0 ? (
                  <>
                    <ul className="pa-search-results">
                      {searchResults.map((company, index) => (
                        <li key={index} onClick={() => selectCompany(company)}>
                          {company.name} ({company.symbol})
                        </li>
                      ))}
                    </ul>
                    <div className="pa-debug">搜索词 "{searchTerm}"，{searchResults.length} 个结果</div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="pa-results-section">
            {isLoading && <div className="pa-loading">正在加载数据...</div>}
            {showResults && selectedCompany && Object.keys(results).length > 0 && !error ? (
              <div className="pa-results">
                <h2>{selectedCompany.name} 分析结果</h2>
                {Object.entries(results).map(([key, value]) => (
                  <ResultItem
                    key={key}
                    label={getLabel(key)}
                    value={value}
                    unit={getUnit(key)}
                    formula={getFormula(key)}
                    realData={getRealData(key, financialData)}
                  />
                ))}
              </div>
            ) : null}
          </div>
        </div>
        {error && <div className="pa-error">{error}</div>}
      </div>
    </div>
  );
}

function getLabel(key) {
  const labels = {
    netProfitMargin: '净润率',
    grossProfitMargin: '毛利率',
    operatingProfitMargin: '营业利润率',
    eps: '每股收益',
    roa: '资产回报率',
    roe: '股本回报率',
  };
  return labels[key] || key;
}

function getUnit(key) {
  return ['eps'].includes(key) ? '' : '%';
}

function getFormula(key) {
  const formulas = {
    netProfitMargin: '净利润 / 营业收入 * 100',
    grossProfitMargin: '毛利润 / 营业收入 * 100',
    operatingProfitMargin: '营业利润 / 营业收入 * 100',
    eps: '净利润 / 流通股数',
    roa: '净利润 / 总资产 * 100',
    roe: '净利润 / 股东权益 * 100',
  };
  return formulas[key] || '未知公式';
}

function getRealData(key, financialData) {
  const realData = {
    netProfitMargin: { 净利润: financialData.netProfit, 营业收入: financialData.revenue },
    grossProfitMargin: { 毛利润: financialData.grossProfit, 营业收入: financialData.revenue },
    operatingProfitMargin: { 营业利润: financialData.operatingProfit, 营业收入: financialData.revenue },
    eps: { 净利润: financialData.netProfit, 流通股数: financialData.outstandingShares },
    roa: { 净利润: financialData.netProfit, 总资产: financialData.totalAssets },
    roe: { 净利润: financialData.netProfit, 股东权益: financialData.shareholdersEquity },
  };
  return Object.fromEntries(
    Object.entries(realData[key] || {}).filter(([_, value]) => value !== undefined)
  );
}

export default ProfitabilityAnalysis;
