// src/components/BlogPost/BlogPost.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { API_URL } from '../../config';
import './BlogPost.css';

function BlogPost() {
  const [post, setPost] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      const response = await fetch(`${API_URL}/api/posts/${id}`);
      const data = await response.json();
      setPost(data);
    };
    fetchPost();
  }, [id]);

  if (!post) return <div>加载中...</div>;

  return (
    <div className="blog-post">
      <h1>{post.title}</h1>
      <p className="post-date">{new Date(post.createdAt).toLocaleDateString()}</p>
      <ReactMarkdown className="post-content">{post.content}</ReactMarkdown>
    </div>
  );
}

export default BlogPost;