import React, { useState, useEffect } from 'react';
import './BlogAdmin.css';
import { API_URL } from '../../config';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function BlogAdmin() {
  const [posts, setPosts] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [tag, setTag] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPosts();
  }, [currentPage]);

  useEffect(() => {
    if (!localStorage.getItem('adminToken')) {
      navigate('/login');
    }
  }, [navigate]);

  const fetchPosts = async () => {
    try {
      const response = await fetch(`${API_URL}/api/posts?page=${currentPage}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error("获取文章列表时出错:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Using API_URL:', API_URL);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('tag', tag);
    if (coverImage) {
      formData.append('coverImage', coverImage);
    }

    try {
      const response = await fetch(`${API_URL}/api/posts`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
        },
        body: formData,
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }
      const newPost = await response.json();
      setPosts([newPost, ...posts]);
      setTitle('');
      setContent('');
      setTag('');
      setCoverImage(null);
      alert('文章发布成功！');
    } catch (error) {
      console.error("发布文章时出错:", error);
      console.error("错误详情:", error.message);
      console.error("请求URL:", `${API_URL}/api/posts`);
      console.error("请求方法:", 'POST');
      console.error("请求头:", {
        'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
      });
      console.error("请求体:", Object.fromEntries(formData));
      alert(`发布文章失败: ${error.message}`);
    }
  };

  const deletePost = async (id) => {
    if (window.confirm('确定要删除这篇文章吗？')) {
      try {
        const response = await fetch(`${API_URL}/api/posts/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setPosts(posts.filter(post => post._id !== id));
        alert('文章已成功删除');
      } catch (error) {
        console.error("删除文章时出错:", error);
        alert(`删除文章失败: ${error.message}`);
      }
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="blog-admin">
      <h1>博客后台管理</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="文章标题"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="文章标签"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          required
        />
        <ReactQuill
          value={content}
          onChange={setContent}
          placeholder="开始写作..."
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setCoverImage(e.target.files[0])}
          required
        />
        <button type="submit">发布文章</button>
      </form>
      <div className="post-list">
        <h2>已发布文章</h2>
        {posts.map((post) => (
          <div key={post._id} className="post-item">
            <h3>{post.title}</h3>
            <p>{post.content.substring(0, 100)}...</p>
            <span>{new Date(post.createdAt).toLocaleDateString()}</span>
            <button onClick={() => deletePost(post._id)}>删除</button>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>上一页</button>
        <span>第 {currentPage} 页</span>
        <button onClick={() => handlePageChange(currentPage + 1)}>下一页</button>
      </div>
    </div>
  );
}

export default BlogAdmin;