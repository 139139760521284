import React from 'react';
import Navigation from './Navigation';
import { FaTimes } from 'react-icons/fa';

function MobileMenu({ isOpen, onClose }) {
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('mobile-menu-overlay')) {
      onClose();
    }
  };

  return (
    <div className={`mobile-menu-overlay ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
      <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
        <button className="close-menu" onClick={onClose}><FaTimes /></button>
        <Navigation className="mobile-nav" />
      </div>
    </div>
  );
}

export default MobileMenu;
