import React from 'react';
import { Link } from 'react-router-dom';

function FooterColumn({ title, links, isExpanded, onToggle }) {
  return (
    <div className="footer-column">
      <h3 className="footer-column-title" onClick={onToggle}>
        {title}
        <span className={`arrow ${isExpanded ? 'up' : 'down'}`}></span>
      </h3>
      <ul className={`footer-column-links ${isExpanded ? 'expanded' : ''}`}>
        {links.map((link, index) => (
          <li key={index}>
            {link.element ? (
              link.element
            ) : link.url.startsWith('http') || link.url === '#' ? (
              <a href={link.url}>{link.name}</a>
            ) : (
              <Link to={link.url}>{link.name}</Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FooterColumn;