import React from 'react';

function BasicInfo({ selectedStock, companyInfo, getFieldLabel, selectedDate }) {
    const getChineseTitle = (name, enname, market) => {
        if (market === 'US' || market === 'HK') {
            return `${name || enname || '未知公司'} 基本信息`;
        }
        return `${name || enname || '未知公司'} 基本信息`;
    };

    return (
        <div className="se-results">
            <h2>{getChineseTitle(selectedStock.name, selectedStock.enname, selectedStock.market)}</h2>
            {/* <div className="se-date-info">财报日期: {selectedDate}</div> */}
            {Object.entries(companyInfo).map(([key, value]) => (
                <div key={key} className="se-result-item">
                    <span className="se-result-label">{getFieldLabel(key)}:</span>
                    <span className="se-result-value">{value || '未知'}</span>
                </div>
            ))}
        </div>
    );
}

export default BasicInfo;
