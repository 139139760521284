import React, { useState, useEffect } from 'react';
import { API_URL } from '../../config';
import './CuttingEdge.css';
import { useNavigate } from 'react-router-dom';
import BouncingBallLoader from '../common/BouncingBallLoader';

const cleanText = (text) => {
  if (!text) return '';
  return text.replace(/<\/?[^>]+(>|$)/g, '');
};

function LoadingSpinner() {
  return (
    <div className="water-ripple-loading"></div>
  );
}

function CuttingEdge() {
  const [articles, setArticles] = useState([]);
  const [rssArticles, setRssArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [loadedRssArticles, setLoadedRssArticles] = useState([]);
  const [isArticlesOpen, setIsArticlesOpen] = useState(true);
  const [isRssOpen, setIsRssOpen] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [rssOffset, setRssOffset] = useState(0);
  const [displayedRssArticles, setDisplayedRssArticles] = useState([]);
  const [isLoadingMoreRss, setIsLoadingMoreRss] = useState(false);
  const [rssError, setRssError] = useState(null);
  const [hasMoreRss, setHasMoreRss] = useState(true);
  const [classicPapers, setClassicPapers] = useState([]);
  const [isClassicOpen, setIsClassicOpen] = useState(true);
  const [isLoadingClassic, setIsLoadingClassic] = useState(false);
  const [classicError, setClassicError] = useState(null);
  const navigate = useNavigate();
  const [cache, setCache] = useState({});

  useEffect(() => {
    const initializeData = async () => {
      try {
        setIsLoading(true);
        const cachedData = localStorage.getItem('cuttingEdgeCache');
        if (cachedData) {
          setCache(JSON.parse(cachedData));
        }
        
        const [articlesResult, rssResult, classicResult] = await Promise.allSettled([
          fetchArticles(),
          fetchRssArticles(false, 10),
          fetchClassicPapers()
        ]);

        setArticles([]);
        setRssArticles([]);
        setDisplayedRssArticles([]);
        setClassicPapers([]);

        if (articlesResult.status === 'fulfilled' && articlesResult.value) {
          setArticles(articlesResult.value);
        }
        
        if (rssResult.status === 'fulfilled' && rssResult.value) {
          const articles = rssResult.value;
          setRssArticles(articles || []);
          setDisplayedRssArticles(articles || []);
          setHasMoreRss(articles && articles.length === 10);
        }
        
        if (classicResult.status === 'fulfilled' && classicResult.value) {
          setClassicPapers(classicResult.value);
        }

        const allFailed = [articlesResult, rssResult, classicResult].every(
          result => result.status === 'rejected'
        );

        if (allFailed) {
          setError('加载数据失败，请稍后重试');
        }
      } catch (error) {
        console.error('初始化数据失败:', error);
        setError('加载数据失败，请稍后重试');
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();
  }, []);

  useEffect(() => {
    localStorage.setItem('cuttingEdgeCache', JSON.stringify(cache));
  }, [cache]);

  const fetchArticles = async (isLoadingMore = false) => {
    const cacheKey = `articles_${offset}`;
    if (cache[cacheKey] && Date.now() - cache[cacheKey].timestamp < 3600000) {
      setArticles(prevArticles => isLoadingMore ? [...prevArticles, ...cache[cacheKey].data] : cache[cacheKey].data);
      setHasMore(cache[cacheKey].hasMore);
      setIsLoading(false);
      setIsLoadingMore(false);
      return;
    }

    if (isLoadingMore) {
      setIsLoadingMore(true);
    } else {
      setIsLoading(true);
    }
    try {
      const response = await fetch(`${API_URL}/api/cutting-edge?offset=${offset}`);
      if (!response.ok) {
        throw new Error(`HTTP 错误！状态: ${response.status}`);
      }
      const data = await response.json();
      setArticles(prevArticles => {
        const newArticles = isLoadingMore ? [...prevArticles, ...data.message.items] : data.message.items;
        const uniqueArticles = Array.from(new Set(newArticles.map(a => a.doi)))
          .map(doi => newArticles.find(a => a.doi === doi));
        return uniqueArticles;
      });
      setHasMore(data.message.items.length === 10);
      setOffset(prevOffset => prevOffset + data.message.items.length);
      setError(null);

      setCache(prevCache => ({
        ...prevCache,
        [cacheKey]: {
          data: data.message.items,
          hasMore: data.message.items.length === 10,
          timestamp: Date.now()
        }
      }));
    } catch (error) {
      console.error("获取最前沿文章时出错:", error);
      setError("暂时无法获取最新文章，请稍后再试。");
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const fetchRssArticles = async (isLoadingMore = false) => {
    if (isLoadingMore) {
      setIsLoadingMoreRss(true);
    }
    
    try {
      const response = await fetch(
        `${API_URL}/api/cutting-edge/rss?offset=${rssOffset}`,
        {
          headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache'
          }
        }
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const articles = await response.json();
      console.log('获取到的RSS文章:', articles);
      
      if (Array.isArray(articles)) {
        if (articles.length > 0) {
          setRssArticles(prev => isLoadingMore ? [...prev, ...articles] : articles);
          setDisplayedRssArticles(prev => isLoadingMore ? [...prev, ...articles] : articles);
          setHasMoreRss(articles.length === 10);
          setRssOffset(prev => prev + articles.length);
          setRssError(null);
        } else {
          setHasMoreRss(false);
        }
      }
      return articles;
    } catch (error) {
      console.error('获取RSS文章失败:', error);
      setRssError(error.message || '暂时无法获取最新文章，请稍后再试');
      return [];
    } finally {
      setIsLoadingMoreRss(false);
    }
  };

  const fetchClassicPapers = async () => {
    const cacheKey = 'classic_papers';
    if (cache[cacheKey] && Date.now() - cache[cacheKey].timestamp < 86400000) {
      setClassicPapers(cache[cacheKey].data);
      setIsLoadingClassic(false);
      return;
    }

    setIsLoadingClassic(true);
    setClassicError(null);
    try {
      const authors = [
        'Einstein', 'Bohr', 'Qian_Xuesen', 'Newton', 'Darwin', 
        'Marie_Curie', 'Feynman', 'Hawking', 'Turing', 'Pauling'
      ];
      const papers = [];

      for (const author of authors) {
        await new Promise(resolve => setTimeout(resolve, 2000)); // 每次请求之间等待2秒
        try {
          const response = await fetch(`${API_URL}/api/arxiv?search_query=au:${encodeURIComponent(author)}&max_results=1`);
          if (!response.ok) {
            throw new Error(`HTTP 错误！状态: ${response.status}`);
          }
          const data = await response.json();
          if (data.papers && data.papers.length > 0) {
            papers.push(data.papers[0]);
          } else {
            console.warn(`未找到 ${author} 的论文`);
          }
        } catch (error) {
          console.error(`获取 ${author} 的论文时出错:`, error);
        }
      }

      setClassicPapers(papers);
      setCache(prevCache => ({
        ...prevCache,
        [cacheKey]: { data: papers, timestamp: Date.now() }
      }));
    } catch (error) {
      console.error('获取经典论文时出错:', error);
      setClassicError('获取经典论文失败，请稍后再试');
    } finally {
      setIsLoadingClassic(false);
    }
  };

  const loadMoreArticles = () => {
    fetchArticles(true);
  };

  const loadMoreRssArticles = () => {
    if (!isLoadingMoreRss && hasMoreRss) {
      fetchRssArticles(true, 10);
    }
  };

  const fetchArxivPapers = async (query, page = 0) => {
    const cacheKey = `arxiv_${query}_${page}`;
    if (cache[cacheKey] && Date.now() - cache[cacheKey].timestamp < 3600000) {
      return cache[cacheKey].data;
    }

    await new Promise(resolve => setTimeout(resolve, 3000)); // 请求间隔

    const response = await fetch(
      `${API_URL}/api/cutting-edge/arxiv?` + 
      `search_query=${encodeURIComponent(query)}&` +
      `start=${page * 10}`
    );

    if (!response.ok) {
      throw new Error(`arXiv API 请求失败: ${response.status}`);
    }

    const data = await response.json();
    cache[cacheKey] = {
      data,
      timestamp: Date.now()
    };

    return data;
  };

  useEffect(() => {
    console.log('displayedRssArticles 更新:', displayedRssArticles);
  }, [displayedRssArticles]);

  if (isLoading) {
    return <BouncingBallLoader />;
  }

  // 只有当完全没有数据且有错误时才显示错误信息
  if (error && articles.length === 0 && displayedRssArticles.length === 0 && classicPapers.length === 0) {
    return <div className="ce-error-message">加载失败: {error}</div>;
  }

  // 即使某些数据加载失败，也显示成功加载的部分
  return (
    <div className="cutting-edge">
      <div className="ce-header">
        <h1 className="ce-title">人类研究<span className="ce-title-highlight">最前沿</span></h1>
        <div className="ce-translation-recommendation">
          <img src={require('../../assets/logos/ImmersiveTranslationLogo.png')} alt="沉浸式翻译" className="ce-translation-logo" />
          <div className="ce-translation-text">
            <p>推荐使用沉浸式翻译来翻译文章内容</p>
            <a href="https://immersivetranslate.com/" target="_blank" rel="noopener noreferrer" className="ce-translation-link">
              安装沉浸式翻译
            </a>
          </div>
        </div>
      </div>
      <button className="ce-back-button" onClick={() => navigate('/all-software')}>返回</button>
      <div className="ce-content">
        <div className="ce-collapsible">
          <button className="ce-collapsible-button" onClick={() => setIsArticlesOpen(!isArticlesOpen)}>
            论文 <span className="ce-collapsible-arrow">{isArticlesOpen ? '▼' : '▶'}</span>
          </button>
          {isArticlesOpen && (
            <>
              <div className="ce-grid">
                {(articles || []).map((article, index) => (
                  <div key={index} className="ce-article-item">
                    <h2 className="ce-article-title">{cleanText(article.title)}</h2>
                    <p className="ce-article-journal">期刊: {article.journal}</p>
                    <p className="ce-article-category">分类: {article.type}</p>
                    <div className="ce-article-summary-container">
                      <p className="ce-article-summary">
                        {cleanText(article.abstract) === '无摘要' ? '暂无摘要信息，请查看原文了解更多。' : cleanText(article.abstract)}
                      </p>
                      <div className="ce-article-summary-gradient"></div>
                    </div>
                    <p className="ce-article-citations">引用次数: {article.citationCount}</p>
                    <a href={article.url} target="_blank" rel="noopener noreferrer" className="ce-article-link">阅读原文</a>
                    {article.doi && <p className="ce-article-doi">DOI: {article.doi}</p>}
                    <p className="ce-article-date">发布日期: {article.date}</p>
                  </div>
                ))}
              </div>
              {hasMore && (
                <button
                  className="ce-load-more"
                  onClick={loadMoreArticles}
                  disabled={isLoadingMore}
                >
                  {isLoadingMore ? '加载中...' : '显示更多'}
                </button>
              )}
            </>
          )}
        </div>
        <div className="ce-collapsible">
          <button className="ce-collapsible-button" onClick={() => setIsRssOpen(!isRssOpen)}>
            热门期刊 <span className="ce-collapsible-arrow">{isRssOpen ? '▼' : '▶'}</span>
          </button>
          {isRssOpen && (
            <>
              <div className="ce-grid">
                {(displayedRssArticles || []).map((article, index) => (
                  <div key={index} className="ce-article-item">
                    <h2 className="ce-article-title">{cleanText(article.title)}</h2>
                    <p className="ce-article-journal">期刊: {article.journal}</p>
                    <div className="ce-article-summary-container">
                      <p className="ce-article-summary">{cleanText(article.summary)}</p>
                      <div className="ce-article-summary-gradient"></div>
                    </div>
                    <a href={article.url} target="_blank" rel="noopener noreferrer" className="ce-article-link">阅读原文</a>
                    <p className="ce-article-date">发布日期: {article.date}</p>
                  </div>
                ))}
              </div>
              {console.log('displayedRssArticles长度:', displayedRssArticles.length)}
              {console.log('hasMoreRss:', hasMoreRss)}
              {displayedRssArticles.length > 0 && (
                hasMoreRss ? (
                  <button
                    className="ce-load-more"
                    onClick={loadMoreRssArticles}
                    disabled={isLoadingMoreRss}
                  >
                    {isLoadingMoreRss ? '加载中...' : '显示更多'}
                  </button>
                ) : (
                  <div className="ce-no-more">没有更多的文章了</div>
                )
              )}
              {rssError && <div className="ce-error-message">{rssError}</div>}
            </>
          )}
        </div>
        <div className="ce-collapsible">
          <button className="ce-collapsible-button" onClick={() => setIsClassicOpen(!isClassicOpen)}>
            现代根基 <span className="ce-collapsible-arrow">{isClassicOpen ? '▼' : '▶'}</span>
          </button>
          {isClassicOpen && (
            <>
              {isLoadingClassic ? (
                <BouncingBallLoader />
              ) : classicError ? (
                <div className="ce-error-message">{classicError}</div>
              ) : (
                <div className="ce-grid">
                  {classicPapers.map((paper, index) => (
                    <div key={index} className="ce-article-item">
                      <h2 className="ce-article-title">{paper.title}</h2>
                      <p className="ce-article-journal">作者: {paper.author}</p>
                      {/* <p className="ce-article-date">发表年份: {paper.year}</p> */}
                      <p className="ce-article-date">重制发表年份: {paper.published}</p>
                      <div className="ce-article-summary-container">
                        <p className="ce-article-summary">{paper.summary}</p>
                        <div className="ce-article-summary-gradient"></div>
                      </div>
                      <a href={paper.url} target="_blank" rel="noopener noreferrer" className="ce-article-link">阅读原文</a>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CuttingEdge;
