import React, { useState } from 'react';

function FinancialData({ financialInfo, incomeInfo, balanceSheetInfo, cashflowInfo, financialIndicatorInfo, mainBusinessInfo, selectedDate }) {
    const [expandedProfitStatement, setExpandedProfitStatement] = useState(false);
    const [expandedBalanceSheet, setExpandedBalanceSheet] = useState(false);
    const [expandedCashflow, setExpandedCashflow] = useState(false);
    const [expandedFinancialIndicator, setExpandedFinancialIndicator] = useState(false);
    const [expandedMainBusiness, setExpandedMainBusiness] = useState(false);

    const uniqueBusinessItems = mainBusinessInfo ? Array.from(new Set(mainBusinessInfo.map(item => item[2])))
        .map(bz_item => mainBusinessInfo.find(item => item[2] === bz_item))
        : [];
    const totalRevenue = uniqueBusinessItems.reduce((sum, item) => sum + item[3], 0);

    return (
        <div className="se-section">
            <h3>财务数据</h3>
            <div className="se-date-info">财报日期: {selectedDate}</div>
            {financialInfo && incomeInfo ? (
                <div className="se-info-container">
                    <div className="se-profit-statement">
                        <h4 onClick={() => setExpandedProfitStatement(!expandedProfitStatement)} className="se-profit-statement-header">
                            利润表
                            <span className={`se-expand-icon ${expandedProfitStatement ? 'expanded' : ''}`}>▼</span>
                        </h4>
                        {expandedProfitStatement && (
                            <div className="se-profit-statement-content">
                                {[
                                    { label: '每股收益(EPS)', value: incomeInfo[0], unit: '元' },
                                    { label: '每股净资产(BPS)', value: financialInfo[1], unit: '元' },
                                    { label: '每股现金流量(CFPS)', value: financialInfo[2], unit: '元' },
                                    { label: '净利润率', value: financialInfo[3], unit: '%' },
                                    { label: '毛利率', value: financialInfo[4], unit: '%' },
                                    { label: '净资产收益率(ROE)', value: financialInfo[5], unit: '%' },
                                    { label: '资产负债率', value: financialInfo[6], unit: '%' },
                                    { label: '营业收入', value: (incomeInfo[1] / 100000000).toFixed(2), unit: '亿元' },
                                    { label: '营业利润', value: (incomeInfo[2] / 100000000).toFixed(2), unit: '亿元' },
                                    { label: '利润总额', value: (incomeInfo[3] / 100000000).toFixed(2), unit: '亿元' },
                                    { label: '净利润', value: (incomeInfo[4] / 100000000).toFixed(2), unit: '亿元' }
                                ].map((item, index) => (
                                    <div key={index} className="se-result-item">
                                        <span className="se-result-label">{item.label}</span>
                                        <span className="se-result-value">
                                            {item.value || '未知'} {item.unit}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {balanceSheetInfo && (
                        <div className="se-balance-sheet">
                            <h4 onClick={() => setExpandedBalanceSheet(!expandedBalanceSheet)} className="se-balance-sheet-header">
                                资产负债表
                                <span className={`se-expand-icon ${expandedBalanceSheet ? 'expanded' : ''}`}>▼</span>
                            </h4>
                            {expandedBalanceSheet && (
                                <div className="se-balance-sheet-content">
                                    {[
                                        { label: '总资产', value: (balanceSheetInfo[0] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '总负债', value: (balanceSheetInfo[1] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '股东权益合计', value: (balanceSheetInfo[2] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '流动资产合计', value: (balanceSheetInfo[3] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '流动负债合计', value: (balanceSheetInfo[4] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '其他应收款', value: (balanceSheetInfo[5] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '固定资产', value: (balanceSheetInfo[6] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '在建工程', value: (balanceSheetInfo[7] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '无形资产', value: (balanceSheetInfo[8] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '长期借款', value: (balanceSheetInfo[9] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '短期借款', value: (balanceSheetInfo[10] / 100000000).toFixed(2), unit: '亿元' }
                                    ].map((item, index) => (
                                        <div key={index} className="se-result-item">
                                            <span className="se-result-label">{item.label}</span>
                                            <span className="se-result-value">
                                                {item.value || '未知'} {item.unit}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {cashflowInfo && (
                        <div className="se-cashflow">
                            <h4 onClick={() => setExpandedCashflow(!expandedCashflow)} className="se-cashflow-header">
                                现金流量表
                                <span className={`se-expand-icon ${expandedCashflow ? 'expanded' : ''}`}>▼</span>
                            </h4>
                            {expandedCashflow && (
                                <div className="se-cashflow-content">
                                    {[
                                        { label: '经营活动产生的现金流量净额', value: (cashflowInfo[0] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '投资活动产生的现金流量净额', value: (cashflowInfo[1] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '筹资动产生的现金流量净额', value: (cashflowInfo[2] / 100000000).toFixed(2), unit: '亿元' },
                                        { label: '自由现金流量', value: (cashflowInfo[3] / 100000000).toFixed(2), unit: '亿元' }
                                    ].map((item, index) => (
                                        <div key={index} className="se-result-item">
                                            <span className="se-result-label">{item.label}</span>
                                            <span className="se-result-value">
                                                {item.value || '未知'} {item.unit}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {financialIndicatorInfo && (
                        <div className="se-financial-indicator">
                            <h4 onClick={() => setExpandedFinancialIndicator(!expandedFinancialIndicator)} className="se-financial-indicator-header">
                                财务指标
                                <span className={`se-expand-icon ${expandedFinancialIndicator ? 'expanded' : ''}`}>▼</span>
                            </h4>
                            {expandedFinancialIndicator && (
                                <div className="se-financial-indicator-content">
                                    {[
                                        { label: '基本每股收益', value: financialIndicatorInfo[0], unit: '元' },
                                        { label: '稀释每股收益', value: financialIndicatorInfo[1], unit: '元' },
                                        { label: '每股净资产', value: financialIndicatorInfo[2], unit: '元' },
                                        { label: '净资产收益率', value: financialIndicatorInfo[3], unit: '%' },
                                        { label: '总资产报酬率', value: financialIndicatorInfo[4], unit: '%' },
                                        { label: '总资产净利润', value: financialIndicatorInfo[5], unit: '%' },
                                        { label: '资产负债率', value: financialIndicatorInfo[6], unit: '%' },
                                        { label: '营业利润率', value: financialIndicatorInfo[7], unit: '%' },
                                        { label: '年化净资产收益率', value: financialIndicatorInfo[8], unit: '%' },
                                        { label: '年化总资产报酬率', value: financialIndicatorInfo[9], unit: '%' }
                                    ].filter(item => item.value !== undefined).map((item, index) => (
                                        <div key={index} className="se-result-item">
                                            <span className="se-result-label">{item.label}</span>
                                            <span className="se-result-value">
                                                {item.value || '未知'} {item.unit}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {mainBusinessInfo && uniqueBusinessItems.length > 0 && (
                        <div className="se-main-business">
                            <h4 onClick={() => setExpandedMainBusiness(!expandedMainBusiness)} className="se-main-business-header">
                                主营业务构成
                                <span className={`se-expand-icon ${expandedMainBusiness ? 'expanded' : ''}`}>▼</span>
                            </h4>
                            {expandedMainBusiness && (
                                <div className="se-main-business-content">
                                    {uniqueBusinessItems.map((item, index) => (
                                        <div key={index} className="se-main-business-item">
                                            <h5>{item[2]}</h5>
                                            <div className="se-result-item">
                                                <span className="se-result-label">营业收入</span>
                                                <span className="se-result-value">{(item[3] / 100000000).toFixed(2)} 亿元</span>
                                            </div>
                                            <div className="se-result-item">
                                                <span className="se-result-label">营业利润</span>
                                                <span className="se-result-value">{(item[4] / 100000000).toFixed(2)} 亿元</span>
                                            </div>
                                            <div className="se-result-item">
                                                <span className="se-result-label">营业成本</span>
                                                <span className="se-result-value">{(item[5] / 100000000).toFixed(2)} 亿元</span>
                                            </div>
                                            <div className="se-result-item">
                                                <span className="se-result-label">利润率</span>
                                                <span className="se-result-value">
                                                    {((item[4] / item[3]) * 100).toFixed(2)}%
                                                </span>
                                            </div>
                                            <div className="se-result-item">
                                                <span className="se-result-label">占主营收入比</span>
                                                <span className="se-result-value">
                                                    {((item[3] / totalRevenue) * 100).toFixed(2)}%
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <p>暂无财务数据</p>
            )}
        </div>
    );
}

export default FinancialData;
