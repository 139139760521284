import React from 'react';
import './BouncingBallLoader.css';

const BouncingBallLoader = () => {
  return (
    <div className="bouncing-ball-loader">
      <div className="ball"></div>
      <div className="shadow"></div>
    </div>
  );
};

export default BouncingBallLoader;