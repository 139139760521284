import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FooterColumn from './FooterColumn';
import FooterBottom from './FooterBottom';
import FooterLegal from './FooterLegal';

function Footer() {
  const [expandedColumns, setExpandedColumns] = useState({});

  const footerColumns = [
    {
      title: "探索与了解",
      links: [
        { name: "主页", url: "https://zapps.pro" },
        { name: "个人网站", url: "#" },
        { name: "博客平台", url: "/blog" },
        { name: "在线工具", url: "#" },
        { name: "移动应用", url: "#" },
        { name: "AI助手", url: "#" },
        { name: "数据分析", url: "#" },
        { name: "学习资源", url: "#" },
        { name: "开源项目", url: "#" },
        { name: "社区论坛", url: "#" }
      ]
    },
    {
      title: "账户",
      links: [
        { name: "管理你的账户", url: "#" },
        { name: "个人中心", url: "#" },
        { name: "云服务", url: "#" }
      ]
    },
    {
      title: "个人网站",
      links: [
        { name: "关于我", url: "/docs/about-me" },
        { name: "我的博客", url: "/blog" },
        { name: "项目展示", url: "#" },
        { name: "技术分享", url: "#" },
        { name: "联系方式", url: "#" },
        { name: "订阅更新", url: "#" },
        { name: "支持我", url: "#" },
        { name: "合作机会", url: "#" },
        { name: "使用条款", url: "/docs/terms-of-use" },
        { name: "隐私政策", url: "/docs/privacy-policy" }
      ]
    },
    {
      title: "专业应用",
      links: [
        { name: "专业版", url: "#" },
        { name: "企业解决方案", url: "#" }
      ]
    },
    {
      title: "教育应用",
      links: [
        { name: "学生专区", url: "#" },
        { name: "教育资源", url: "#" }
      ]
    },
    {
      title: "我的价值观",
      links: [
        { name: "可持续发展", url: "/docs/sustainable-development"},
        { name: "技术伦理", url: "/docs/tech-ethics" },
        { name: "隐私保护", url: "/docs/privacy-protection" },
        { name: "社会责任", url: "/docs/social-responsibility" }
      ]
    },
  ];

  const toggleColumn = (index) => {
    setExpandedColumns(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  return (
    <footer className="App-footer">
      <div className="footer-content">
        <div className="footer-columns">
          {footerColumns.map((column, index) => (
            <FooterColumn 
              key={index} 
              title={column.title} 
              links={column.links} 
              isExpanded={expandedColumns[index]}
              onToggle={() => toggleColumn(index)}
            />
          ))}
        </div>
        <FooterBottom />
        <FooterLegal />
        <p className="footer-china">中国大陆</p>
      </div>
    </footer>
  );
}

export default Footer;